import { Layout, Menu, MenuProps } from 'antd';
import classNames from 'classnames/bind';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as DownIcon } from '~/assets/icons/chevron-down.svg';
import { ReactComponent as RightIcon } from '~/assets/icons/chevron-right.svg';
import { RootState } from '~/store/store';
import { findActiveMenu } from '~/utils/utils';

import styles from './index.module.scss';
import MENU, { MenuItem } from './Menu';

const cx = classNames.bind(styles);

const Sider = (): React.ReactElement => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const userRoles = useSelector(
    (state: RootState) => state.auth?.tokenInfo?.groups || [],
  );

  React.useEffect(() => {
    setOpenKeys(findActiveMenu(location));
  }, [location.pathname]);

  /* eslint-disable max-nested-callbacks */
  const sideMenu: MenuProps['items'] = useMemo(() => {
    return MENU.map((el: MenuItem) => {
      if (
        el.roles &&
        !el.roles.some((role: string) => userRoles?.includes(role))
      )
        return null;

      return {
        key: el?.path || el.title,
        icon: el.icon,
        label: el?.path ? <Link to={el.path}>{el.title}</Link> : el.title,
        children:
          el?.children &&
          el.children.length &&
          el.children.map((child: MenuItem) => {
            if (
              child.roles &&
              !child.roles.some((role: string) => userRoles?.includes(role))
            )
              return null;

            return {
              key: child.path,
              label: child.path ? (
                <Link to={child.path}>{child.title}</Link>
              ) : (
                child.title
              ),
            };
          }),
      };
    });
  }, [userRoles]);

  const selectedKey = location.pathname.replace(/\/(detail|edit)(.+)$/, '');

  return (
    <Layout.Sider className={cx('sider')} width={240} breakpoint="lg">
      <Menu
        mode="inline"
        selectedKeys={[selectedKey]}
        openKeys={openKeys}
        style={{ height: '100%', borderRight: 0 }}
        items={sideMenu}
        className={cx('sider-menu')}
        onOpenChange={setOpenKeys}
        expandIcon={({ isOpen }): any => {
          return isOpen ? (
            <span className={cx('icon')}>
              <DownIcon />
            </span>
          ) : (
            <span className={cx('icon')}>
              <RightIcon />
            </span>
          );
        }}
      />
    </Layout.Sider>
  );
};

export default Sider;
