import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { apolloClient } from '~/apollo';
import { DEFAULT_PER_PAGE } from '~/constants/common';
import {
  CHANGE_USER_STATUS,
  CREATE_USER,
  DELETE_MANY_USERS,
  DELETE_USER,
  IMPORT_CSV_END_USER,
  RESEND_EMAIL,
  UPDATE_USER,
} from '~/graphql/mutations';
import {
  EXPORT_CSV_END_USER,
  FILTER_LIST_USER,
  GET_USER,
  LIST_USER,
} from '~/graphql/queries';
import {
  ExportCsvEndUserResponse,
  ImportCsvEndUserResponse,
  ListUserResponse,
  MutationImportCsvEndUserArgs,
  QueryExportCsvEndUserArgs,
  SortOrder,
  UpdateUserInput,
  UserStatus,
  UserWhereInput,
} from '~/models/common';
import { IUser } from '~/models/user';

dayjs.extend(utc);
export const createUser = async (payload: IUser): Promise<any> => {
  return apolloClient.mutate({
    mutation: CREATE_USER,
    variables: { data: payload },
  });
};

/* eslint-disable  complexity */
export const listUser = async (payload): Promise<ListUserResponse> => {
  const { where, pagination, orderBy } = payload;

  const res = await apolloClient.query({
    query: LIST_USER,
    variables: {
      where:
        Object.keys(where).length > 0
          ? {
              id: where.id
                ? {
                    contains: where.id,
                  }
                : undefined,
              name: where.name
                ? {
                    contains: where.name,
                  }
                : undefined,
              department: where.department
                ? { is: { name: { contains: where.department } } }
                : undefined,
              company:
                where.companyId || where.business_name
                  ? {
                      is: {
                        id: where.companyId
                          ? { contains: where.companyId }
                          : undefined,
                        name: where.business_name
                          ? { contains: where.business_name }
                          : undefined,
                      },
                    }
                  : undefined,
              email: where.email
                ? {
                    contains: where.email,
                  }
                : undefined,
              birthday:
                where.ageFrom || where.ageTo
                  ? {
                      lte: where.ageFrom,
                      gte: where.ageTo,
                    }
                  : undefined,
              userServices:
                where.serviceIds && where.serviceIds.length > 0
                  ? {
                      some: {
                        deletedAt: null,
                        serviceId: {
                          in: !Array.isArray(where.serviceIds)
                            ? where.serviceIds.split(',')
                            : where.serviceIds,
                        },
                      },
                    }
                  : undefined,
              userQuestionnaireAnswers: where.userQuestionnaireAnswers
                ? where.userQuestionnaireAnswers
                : undefined,
              questionnaires: Array.isArray(where.finishedAt)
                ? {
                    some: {
                      finishedAt: {
                        gt: where.finishedAt[0]
                          ? dayjs
                              .utc(where?.finishedAt[0])
                              .startOf('day')
                              .toISOString()
                          : undefined,

                        lt: where.finishedAt[1]
                          ? dayjs
                              .utc(where?.finishedAt[1])
                              .endOf('day')
                              .toISOString()
                          : undefined,
                      },
                    },
                  }
                : undefined,
            }
          : undefined,
      pagination: {
        page: pagination?.page || 1,
        perPage: DEFAULT_PER_PAGE,
      },
      orderBy: orderBy?.companyName
        ? {
            company: {
              name: orderBy.companyName,
            },
          }
        : orderBy || {
            createdAt: SortOrder.Desc,
          },
    },
  });

  return res.data.listUser;
};

export const getUser = async (userId: string): Promise<any> => {
  return apolloClient.query({
    query: GET_USER,
    variables: {
      userId,
    },
  });
};

export const updateUser = async (payload: UpdateUserInput): Promise<any> => {
  return apolloClient.mutate({
    mutation: UPDATE_USER,
    variables: { data: payload },
  });
};

export const resendVerifyEmail = async (ids: string[]): Promise<any> => {
  return apolloClient.mutate({
    mutation: RESEND_EMAIL,
    variables: {
      ids,
    },
  });
};

export const deleteUser = async (userId: string): Promise<any> => {
  return apolloClient.mutate({
    mutation: DELETE_USER,
    variables: {
      id: userId,
    },
  });
};

export const filterListUser = async ({
  where,
}: {
  where: UserWhereInput;
}): Promise<ListUserResponse> => {
  const res = await apolloClient.query({
    query: FILTER_LIST_USER,
    variables: {
      where,
    },
  });
  return res.data.listUser;
};

export const changeUserStatus = async (
  id: string,
  status: UserStatus,
): Promise<any> => {
  return apolloClient.mutate({
    mutation: CHANGE_USER_STATUS,
    variables: {
      id,
      status,
    },
  });
};

export const importCsvEndUser = async (
  payload: MutationImportCsvEndUserArgs,
): Promise<ImportCsvEndUserResponse> =>
  apolloClient
    .mutate<
      { importCSVEndUser: ImportCsvEndUserResponse },
      MutationImportCsvEndUserArgs
    >({
      mutation: IMPORT_CSV_END_USER,
      variables: payload,
    })
    .then((res) => res.data?.importCSVEndUser!);

export const deleteManyUsers = async (ids: string[]): Promise<any> => {
  return apolloClient.mutate({
    mutation: DELETE_MANY_USERS,
    variables: {
      ids,
    },
  });
};

export const exportCsvEndUser = async (
  payload: QueryExportCsvEndUserArgs,
): Promise<ExportCsvEndUserResponse> => {
  const res = await apolloClient.query<{
    exportCSVEndUser: ExportCsvEndUserResponse;
  }>({
    query: EXPORT_CSV_END_USER,
    variables: payload,
  });
  return res.data.exportCSVEndUser;
};
